<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Proveedores</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('proveedores.create')" dark color="#023145" width="140" class="float-right"
                 @click="handleShowCreate">
            Nuevo
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
              v-model="search_supplier"
              label="Buscar"
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              @keydown="handleInputSearchByEnter">
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('proveedores.filters')" dark color="#023145" outlined width="140"
                 class="float-right" @click="handleShowFilters">
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
                v-model="selectedRows"
                :headers="headers"
                :items="suppliers"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                show-select
                :single-select="true"
                @item-selected="handleSelectItem"
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait"

            >
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todos los proveedores
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
                <br>
                <v-icon v-if="item.email" class="mr-1" size="13" color="#303197">fas fa-envelope</v-icon>
                <span class="font-italic email-company">
                                    {{ item.email }}
                                </span>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn v-if="this.$hasPermision('proveedores.edit')" class="mt-3" dark color="blue" outlined
                   @click="handleEdit">
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn v-if="show_btn_active && this.$hasPermision('proveedores.change_status')" class="ml-4 mt-3" dark
                   color="green" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn v-if="!show_btn_active && this.$hasPermision('proveedores.change_status')" class="ml-4 mt-3" dark
                   color="amber" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn v-if="this.$hasPermision('proveedores.destroy')" class="ml-4 mt-3" dark color="red" outlined
                   @click="handleDelete">
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <create-update ref="createUpdate"></create-update>
    <Filters ref="filters"></Filters>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools'
import CreateUpdate from '../../../components/suppliers/CreateUpdate'
import Filters from '../../../components/suppliers/FiltersPaginated'

export default {
  name: 'Suppliers',
  components: {
    CreateUpdate,
    Filters,
    OfficeLogin
  },
  watch: {
    options: {
      handler() {
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_supplier
        if (this.filterPaginated.name != null || this.filterPaginated.ruc != null||this.filterPaginated.email != null||this.filterPaginated.phone
        || this.filterPaginated.trade_name!=null) {
          this.getAllSuppliers({
            paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
            name: this.filterPaginated.name,
            ruc: this.filterPaginated.ruc,
            email: this.filterPaginated.email,
            phone: this.filterPaginated.phone,
            trade_name: this.filterPaginated.trade_name
          })

        }else{
          if (search!=null && search.trim().length > 0) {
            // this.perPage=-1
            this.getAllSuppliers({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              name: search
            })
          } else {
            this.getAllSuppliers({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})
          }
        }

      },
      deep: true,
    }
  },
  data() {
    return {
      search_supplier: null,
      drawer: false,
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],
      itemsBreadcrumbs: [
        {
          text: 'Logística',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'RAZÓN SOCIAL', value: 'name'},
        {text: 'RUC', value: 'ruc'},
        {text: 'NOMBRE COMERCIAL', value: 'trade_name'},
        {text: 'TELÉFONO', value: 'phone'},
        {text: 'DIRECCIÓN', value: 'address'}
      ],
      loading: false,
      dataTable: {page: 1},
      options: {},
    }
  },
  computed: {
    ...mapState('suppliers', [
      'suppliers',
      'paginated',
      'filterPaginated'
    ])
  },
  mounted() {
    this.changeFilterPaginated({ name: null,
      ruc: null,
      email: null,
      phone: null,
      trade_name: null})
  },
  methods: {
    ...mapActions('suppliers', [
      'getSuppliers',
      'change_status',
      'delete',
      'changeFilterPaginated'
    ]),
    async onPageChange(page) {
      this.loading = true
      await this.getSuppliers({page: page, paginated: true})
          .then(result => this.loading = false)
          .catch(errr => this.loading = false)
    },
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0
      if (this.show_options_crud) {
        this.show_btn_active = !this.selectedRows[0].active
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item)
      } else {
        let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1)
        }
      }
      this.showOptionCrud()
    },
    handleShowCreate() {
      this.$refs.createUpdate.showForm('Nuevo proveedor', 'create')
    },
    handleEdit() {
      let supplier = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      this.$refs.createUpdate.showForm('Modificar datos de proveedor', 'update', supplier)
    },
    handleShowFilters() {
      this.$refs.filters.showForm('Filtrar datos de proveedores')
    },
    async getAllSuppliers(filters) {
      this.loading=true
      await this.getSuppliers(filters)
      .then(res=>this.loading=false)
      .catch(err=>this.loading=false)
    },
    handleInputSearchByEnter(KeyboardEvent) {
      if (KeyboardEvent.code == 'Enter') {
        this.handleSearch();
      }
    },
    async handleSearch() {
      this.selectedRows = []
      this.changeFilterPaginated({ name: null,
        ruc: null,
        email: null,
        phone: null,
        trade_name: null})
      this.showOptionCrud()
      this.loading = true
      let filters = {}
      this.dataTable.page = 1
      if (this.search_supplier.trim().length > 0) {
        filters = {
          name: this.search_supplier,
        }
      } else {
        filters = {
          paginated: true
        }
      }
      await this.getSuppliers(filters).catch(err=>this.loading=false)
      .then(res=>this.loading=false)
    },
    async handleChangeStatus() {
      let supplier = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      await this.change_status(supplier)
    },
    async handleDelete() {
      let supplier = this.selectedRows[0]
      await this.delete(supplier)
      this.selectedRows = []
      this.showOptionCrud()
    }
  },
  created() {
    // this.getAllSuppliers({paginated:true});
  }
}
</script>

<style scoped>

</style>