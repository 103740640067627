<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="11">
                        <v-text-field 
                            ref="ruc"
                            v-model="supplierForm.ruc" 
                            label="RUC" 
                            outlined
                            :loading="searching"
                            :rules="[() => !!supplierForm.ruc || 'Es necesario ingresar un ruc']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="pl-0">
                        <v-icon class="mt-2" color="blue"
                            @click="handleSearchDocument">
                            fas fa-search
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="name"
                            v-model="supplierForm.name" 
                            label="Razón Social" 
                            outlined 
                            :rules="[() => !!supplierForm.name || 'Es necesario ingresar la razón social']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="supplierForm.trade_name" 
                            label="Nombre Comercial" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="supplierForm.phone" 
                            label="Teléfono" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="supplierForm.email" 
                            label="Email" 
                            outlined 
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            label="Dirección"
                            v-model="supplierForm.address"
                            >
                        </v-textarea>
                    </v-col>
                </v-row>
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            searching: false,
            supplierForm: {
                id: null,
                name: null,
                ruc: null,
                address: null,
                email: null,
                phone: null,
                trade_name: null
            }
        }
    },
    computed: {
        ...mapState("customers", [
            "person"
        ]),
    },
    methods: {
        ...mapActions('suppliers', [
            'store',
            'update'
        ]),
        ...mapActions("customers", [
            "emptyPerson",
            "searchDocument",
        ]),
        async showForm(title, action, supplier = null) {
            await this.clearData()
            this.titleForm = title
            this.action = action
            if (supplier) {
                this.setSupplier(supplier)
            }
            this.drawer = !this.drawer
        },
        clearData() {
            this.titleForm = null
            this.action = null
            this.supplierForm.id = null
            this.supplierForm.name = null
            this.supplierForm.ruc = null
            this.supplierForm.address = null
            this.supplierForm.email = null
            this.supplierForm.phone = null
            this.supplierForm.trade_name = null
            this.clearErrors();
        },
        setSupplier(supplier) {
            this.supplierForm.id = supplier.id
            this.supplierForm.name = supplier.name
            this.supplierForm.ruc = supplier.ruc
            this.supplierForm.address = supplier.address
            this.supplierForm.email = supplier.email
            this.supplierForm.phone = supplier.phone
            this.supplierForm.trade_name = supplier.trade_name
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        handleCancel() {
            this.closeForm()
        },
        async handleStore() {
            if (!this.existErrors()) {
                await this.store(this.supplierForm)
                this.closeForm()
            }            
        },
        async handleUpdate() {
            if (!this.existErrors()) {
                await this.update(this.supplierForm)
                this.closeForm()   
            }            
        },
        async handleSearchDocument() {
            this.searching = true;
            if (this.supplierForm.ruc) {
                let document = this.supplierForm.ruc;
                await this.searchDocument(document);
                this.searching = false;
                if (this.person) {
                let personFounded = Object.assign({}, this.person);
                this.emptyPerson();
                this.supplierForm.ruc = personFounded.document;
                this.supplierForm.name = personFounded.name;
                this.supplierForm.address = personFounded.address;
                }
            } else {
                this.searching = false;
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.supplierForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.supplierForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.supplierForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>